import secureLocalStorage from 'react-secure-storage'

export const loginBaseUrl = 'https://dev-test-api.zylk.io/fujc_prod_login'

export const baseUrl = 'https://dev-test-api.zylk.io/fujc_prod_api'

export const authToken = secureLocalStorage.getItem('auth_token')
export const userId = JSON.parse(localStorage.getItem('user_id'))

export const defaultProfileImage =
  'https://zylk.s3.ap-south-1.amazonaws.com/organization4/common_profile_pic.jpg'

export const FirstUserId = 1
